import styled from '@emotion/styled';
import { IDataLayerGlobal } from 'src/interface/data-layer';

import _ from 'lodash';
import CardFull from 'src/components/cards/card-full';
import Carousels from 'src/components/carousels';
import { SHOW_COMMEMORATE } from 'src/constants';
import { PSection } from 'src/interface/section';
import { controller } from 'src/switch-themes';
import useResponsive from 'src/utils/devices';
type Props = {
  highlight1: PSection['highlight1'];
  eventDataLayer: IDataLayerGlobal;
};
const { description } = controller;

const SectionOne = ({ highlight1, eventDataLayer }: Props) => {
  const data = highlight1?.data.slice(0, 5);
  const { isMobile } = useResponsive();

  return (
    <SectionWrapper id='section-1' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
      <h2 className='seo-index-h2'>{description}</h2>

      <div className={`grid-sec-one container`}>
        <div className='block-1'>
          <Carousels slideShow={1} autoplay={true} infinite={true}>
            {_.isArray(data) &&
              data.map(
                (item: any, i: number) =>
                  (isMobile ? i < 5 : i < 3) && (
                    <CardFull
                      key={i}
                      data={item}
                      showBlurb={false}
                      // ! DATALAYER
                      eventDataLayer={{
                        ...eventDataLayer,
                        data: {
                          heading: 'highlight',
                          block: '1',
                          title: data[0]?.title,
                          carousel: i + 1
                        }
                      }}
                    />
                  )
              )}
          </Carousels>
        </div>
        <div className='block-2'>
          <CardFull
            data={data[3]}
            showBlurb={false}
            // ! DATALAYER
            eventDataLayer={{
              ...eventDataLayer,
              data: {
                heading: 'highlight',
                title: data[3]?.title,
                block: '2',

                index: 4
              }
            }}
          />
          <CardFull
            data={data[4]}
            showBlurb={false}
            // ! DATALAYER
            eventDataLayer={{
              ...eventDataLayer,
              data: {
                heading: 'highlight',
                block: '2',

                title: data[4]?.title,
                index: 5
              }
            }}
          />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionOne;

const SectionWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 30px 0;

  .seo-index-h2 {
    position: absolute;
    opacity: 0;
  }

  .grid-sec-one {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: 'block-1 block-2';
    grid-gap: 2px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-areas: 'block-1';
    }
    .block-1 {
      grid-area: block-1;
      max-width: 100%;
      overflow: hidden;
      .title {
        font-size: 36px;
        @media (max-width: 1024px) {
          font-size: 18px;
        }
      }

      .card-image {
        padding-top: 66%;
        /* border-top-left-radius: 48px; */
        img {
          object-position: top;
        }
      }
    }
    .block-2 {
      grid-area: block-2;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 31px;
      /* .card-full {
        padding-bottom: 3px;
      } */
      .card-image {
        padding-top: 66%;
      }
    }
    .block-3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
      grid-area: block-3;
      .card-image {
        padding-top: 75%;
      }
    }
  }
`;
